.btn-arrow-right {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 0 !important;
  margin-right: 1px;
  width: 98%;
  height: 34px;

  &[disabled] {
    opacity: 1.00;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 4px;
    /* move it down because of rounded corners */
    height: 24px;
    /* button_inner_height / sqrt(2) */
    width: 24px;
    /* same as height */
    background: inherit;
    /* use parent background */
    border: inherit;
    /* use parent border */
    border-left-color: transparent;
    /* hide left border */
    border-bottom-color: transparent;
    /* hide bottom border */
    border-radius: 0 !important;
  }

  &:before {
    left: -13px;
  }

  &:after {
    right: -12px;
    top: 5px;
  }

  &.btn-arrow-right {
    padding-left: 36px;

    &:before,
    &:after {
      transform: rotate(45deg);
      /* rotate right arrow squares 45 deg to point right */
    }
  }
}

.btn-arrow-right:after {
  /* bring arrow pointers to front */
  z-index: 3;
}

.btn-arrow-right:before {
  /* hide arrow tails background */
  background-color: white;
}


/* Large */
.btn-lg.btn-arrow-right,
.btn-group-lg>.btn-arrow-right {
  padding-left: 22px;
  padding-right: 22px;
  margin-right: 0px;

  &:before,
  &:after {
    top: 6px;
    /* move it down because of rounded corners */
    height: 32px;
    /* button_inner_height / sqrt(2) */
    width: 32px;
    /* same as height */
  }

  &:before {
    left: -16px;
  }

  &:after {
    right: -16px;
  }

  &.btn-arrow-right {
    padding-left: 44px;
  }
}

/* Small */
.btn-sm.btn-arrow-right,
.btn-group-sm>.btn-arrow-right {
  padding-left: 14px;
  padding-right: 14px;
  margin-right: -1px;

  &:before,
  &:after {
    top: 4px;
    /* move it down because of rounded corners */
    height: 20px;
    /* button_inner_height / sqrt(2) */
    width: 20px;
    /* same as height */
  }

  &:before {
    left: -10px;
  }

  &:after {
    right: -10px;
  }

  &.btn-arrow-right {
    padding-left: 28px;
  }
}

/* Extra Small */
.btn-xs.btn-arrow-right,
.btn-group-xs>.btn-arrow-right {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -1px;

  &:before,
  &:after {
    top: 3px;
    /* move it down because of rounded corners */
    height: 14px;
    /* button_inner_height / sqrt(2) */
    width: 14px;
    /* same as height */
  }

  &:before {
    left: -7px;
  }

  &:after {
    right: -7px;
  }

  &.btn-arrow-right {
    padding-left: 20px;
  }
}

/* Button Groups */
.btn-group>.btn-arrow-right {

  &:hover,
  &:focus {
    z-index: initial;
  }
}

.btn-group>.btn-arrow-right+.btn-arrow-right {
  margin-left: 0px;
}

.btn-group>.btn:not(.btn-arrow-right):not(.btn-arrow-left) {
  z-index: 1;
}

.left-arrow:before {
  left: -29px !important;
}

.right-arrow:after {
  right: 6px !important;
  height: 0px;
}
