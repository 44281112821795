@import '~ngx-toastr/toastr';
@import '~material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0px !important;
    font-family: 'Poppins' !important;
    font-size: 16px;
    background-color: #fff;
    -webkit-print-color-adjust: exact;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Poppins' !important;
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'Poppins' !important;
}

input,
textarea,
select {
    font-size: 13px !important;
}

.content-wrapper {
    background: white !important;
}

.btn-snape-red {
    background: #FF5C7B !important;
    color: #fff !important;
}

.btn-cancel-grey {
    background: #14171A !important;
    opacity: 0.2;
    color: #fff !important;
}

.snape-red,
.fa-snape-red {
    color: #FF5C7B;
}

.cursor-pointer {
    cursor: pointer;
}

.mfooterBtn button {
    width: 135px;
    height: 36px;
}

.mfooterContinue button {
    width: 100%;
    height: 36px;
}

.fSize14px {
    font-size: 14px;
}

.bRad10px {
    border-radius: 10px;
}

.btn-snape-bak {
    background: #FF5C7B;
    color: #fff;
}

.login-bgpic {
    background-image: url('./assets/loginimg.svg');
    object-fit: cover;
    height: 100vh;
    background-size: cover;
}

.auth-ui {
    width: 75%;
}

.forgetpass {
    color: #000 !important;
    font-size: 12px;
}

.w100 {
    width: 100%;
}

.w95 {
    width: 95%;
}

::placeholder {
    color: #ccc;
    font-size: 12px;
}

.pro-Bordr {
    border-left: 1px solid #ddd;
}

.bRadius5px {
    border-radius: 5px;
}

.vh100 {
    height: 100vh;
}

.deactivate {
    position: absolute;
    bottom: 0;
    right: 20px;
    color: #aaa;
}

button.close {
    padding: 0px 5px;
    color: #FF5C7B;
    opacity: 0.5;
    outline: none;
}

button.close:hover {
    opacity: 1;
    color: #FF5C7B;
    /* background: #fcedf0; */
    /* border-radius: 50%; */
}

.text-grey {
    color: #000;
    opacity: 0.6;
}

.fontSize12px {
    font-size: 12px;
}

.fw600 {
    font-weight: 600;
}

.fw500 {
    font-weight: 500;
}

.login-card {
    height: auto;
    width: 75%;
}

.login-width {
    width: 500px;
}

.button-style {
    height: 38px;
    width: 150px;
    border: none;
    border-radius: 5px;
}

.sc-sidemenu {
    background: #FF5C7B;
    height: 100vh;
}

.btn-flavr-green {
    color: #04DC77;
    background-color: #E5FCF1;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {

    .mob-mt-4 {
        margin-top: 1rem;
    }
}


@media (min-width: 576px) {
    .container {
        max-width: 576px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

/* this code for angular multi select dropdown start*/
.c-btn {
    background-color: #f6f6f6 !important;
}

.vertical.selfScroll {
    background-color: #f6f6f6 !important;
}

.select-all {
    background-color: #f6f6f6 !important;
}

.btn-pink {
    color: white;
    background-color: #FF5C7B;
}

.selected-list .c-btn {
    background-color: #f6f6f6 !important;
    /* border: 1px solid #EAEDF1 !important; */
    min-height: 30px !important;
    height: 30px !important;
}

.selected-list .c-btn span {
    color: #818c99 !important;
    font-size: 13px !important;
}

.vertical.selfScroll {
    background-color: #ffffff !important;
}

.pure-checkbox input+label:hover {
    color: #FF5C7B !important;
}

.pure-checkbox input[type="checkbox"]:checked+label:before {
    background: #FF5C7B !important;
}

.pure-checkbox input[type="checkbox"]+label:before {
    color: #FF5C7B !important;
    border: 1px solid #FF5C7B !important;
}

.selected-list .c-list .c-token {
    background: #f6f6f6 !important;
}

.selected-list .c-list .c-token .c-remove svg {
    fill: #FF5C7B !important;
}

/* .single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
    background: #FF5C7B !important;
}
.single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    border-color: white !important;
} */

.pure-checkbox input[type=checkbox]:checked+label:after {
    color: white !important;
}

.scrollable-content {
    background-color: #f6f6f6 !important;
}

.pure-checkbox label {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #61ACEB !important;
    margin-bottom: 0;
    cursor: pointer;
}

.pure-checkbox label:hover {
    color: #FF5C7B !important;
}

/* this code for angular cuppalabs multi dropdown end*/

button:focus {
    outline: none !important;
}

/* scrollbar start */
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FF5C7B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FF5C7B;
}

/* scrollbar end */
.text-danger {
    color: #dc3545 !important;
    font-size: 12px !important;
}

/* this code for pagination start*/
.ngx-pagination .current {
    background: #ff5c7b !important;
}

/* this code for pagination end*/
/* date range picker start*/

.theme-green .bs-datepicker-head {
    background-color: #ff5c7b !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #ff5c7b !important;
}

/* date range picker end*/
/* check box */
.checkbox-label {
    color: #FF9226 !important;
}

.form-check-input:checked {
    background-color: #FF9226 !important;
    border-color: #FF9226 !important;
}

.form-check-input:focus {
    box-shadow: unset !important;
}

/* check box */
/* reload btn start */
.reload-icon {
    text-align: center;
    cursor: pointer;
    margin-top: 7px;
}

.reload-icon i {
    background-color: #f6f6f6 !important;
    color: #818c99;
    font-size: 13px;
}

.pure-checkbox input[type=checkbox]+label {
    font-size: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* reload btn end */
.mandatory {
    color: #dc3545;
}

/* this code for primeng time picker start */
.p-calendar {
    width: 100%;
}

.p-calendar-w-btn .p-datepicker-trigger {
    height: 42px !important;
    background-color: #ffdae1;
    color: #f3002e;
    border: 1px solid #ffdae1;
}

/* .p-calendar-w-btn .p-inputtext {
    border: 1px solid red;
}

*/
/* this code for primeng time picker end */
/* this code for edge password show hide focus */
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/* this code for edge password show hide focus */
/* https://stackoverflow.com/questions/61449079/how-to-hide-the-eye-from-a-password-input-in-ms-edge-and-ie */